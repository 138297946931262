// Error
export const SERVER_ERROR = 'SERVER_ERROR';
export const EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';

// Auth
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const USER_IS_BANNED_ERROR = 'USER_IS_BANNED_ERROR';

export const LOGOUT = 'LOGOUT';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const INITIAL = 'INITIAL';
export const INVALID_USER = 'INVALID_USER';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

// User
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_MULTIPLE_USER_SUCCESS = 'DELETE_MULTIPLE_USER_SUCCESS';
export const GET_MANAGE_SUCCESS = 'GET_MANAGE_SUCCESS';
