const SUCCESS = 200;
const CREATED = 201;

const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NO_EXIST = 404;
const ALREADY_EXIST = 407;
const PASSWORD_NOT_MATCH = 410;

const INTERNAL_SERVER_ERROR = 500;
const INVALID_USER = 410;
const PASSWORD_NOT_EQUAL = 411;
const USER_NOT_VERIFIED = 412;

const VERIFY_CODE_INCORRECT = 413;
const USER_IS_BANNED = 414;

module.exports = {
  SUCCESS,
  CREATED,
  BAD_REQUEST,
  UNAUTHORIZED,
  PASSWORD_NOT_MATCH,
  FORBIDDEN,
  NO_EXIST,
  ALREADY_EXIST,
  INTERNAL_SERVER_ERROR,
  INVALID_USER,
  PASSWORD_NOT_EQUAL,
  USER_NOT_VERIFIED,
  VERIFY_CODE_INCORRECT,
  USER_IS_BANNED,
};
